.innerWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: 100%;
    padding-top: 44px;
    overflow-y: auto;
}

.innerWrapper::-webkit-scrollbar {
    background-color: transparent;
    width: 5px;
    height: 6px;
    border: 1px solid transparent;
}
.innerWrapper::-webkit-scrollbar-thumb {
    background-color: var(--color-gray-light);
    border-radius: 8px;
}

.filters .title {
    font-weight: 600;
    padding: 6px 16px 18px;
    transform: translate(0, -1px);
}

.title {
    position: fixed;
    top: 66px;
    z-index: 1;
    width: 338px;
    background-color: var(--color-gray-3);
}

.spinner {
    margin: 10px auto;
}

.applyButton {
    margin-top: auto;
    margin-bottom: 5px;
    box-shadow: none;
}



@media screen and (max-width: 480px) {
    .innerWrapper {
        gap: 2px;
    }

    .title {
        width: 356px;
    }

    .applyButton {
        margin-bottom: 60px;
    }
}
