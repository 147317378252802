.wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px 0 40px;
    margin-left: 367px;
    max-height: calc(95vh - 100px);
    border-radius: 4px;
    background-color: var(--color-white);
    transition: margin-left .3s ease-in-out;
}

.radioGroupMargin {
    margin-left: 20px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.window {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0 20px;
    transition: margin-left .3s ease-in-out;
    overflow-y: auto;
}
.window::-webkit-scrollbar {
    background-color: transparent;
    width: 5px;
    height: 6px;
    border: 1px solid transparent;
}
.window::-webkit-scrollbar-thumb {
    background-color: var(--color-gray-light);
    border-radius: 8px;
}

.wrapperFull {
    margin-left: 0;
}

.block {
    display: flex;
    gap: 12px;
    align-items: center;
}

.collapse > div:first-child {
    padding: 4px 16px !important;
}

.collapseGroup > div:first-child {
    padding: 4px 16px !important;
    background-color: var(--color-gray-2) !important;
    border-radius: 0 !important;
}

.title {
    font-weight: 600;
}

.infoCircle {
    position: relative;
    top: 2px;
}

.separator {
    border-bottom: 1px solid #EDF2F4 !important;
}

.tables {
    display: flex;
    gap: 8px 32px;
    overflow-x: auto;
}
.tables::-webkit-scrollbar {
    background-color: transparent;
    width: 5px;
    height: 6px;
    border: 1px solid transparent;
}
.tables::-webkit-scrollbar-thumb {
    background-color: var(--color-gray-light);
    border-radius: 8px;
}

.spinner {
    margin: 10px auto;
}




@media screen and (max-width: 1200px) {
    .wrapper {
        padding-bottom: 50px;
    }
}



@media screen and (max-width: 1000px) {
    .wrapper {
        padding-bottom: 70px;
    }
}



@media screen and (max-width: 768px) {
    .wrapper {
        padding-bottom: 90px;
    }
}



@media screen and (max-width: 480px) {
    .wrapper {
        padding-bottom: 100px;
        margin-left: 0;
    }

    .tables {
        flex-wrap: wrap;
        overflow-x: hidden;
    }
}

