.wrapper {
    display: flex;
    max-width: 100%;
    padding: 10px 0 3px;
}

.mainColumn {
    min-width: 40px;
}

.marginWrapper {
    margin-bottom: 20px;
}

.mainColumn, .column {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.mainColumn > span:first-child {
    font-weight: 600;
}

.paddingLeft {
    padding-left: 16px;
}

.column {
    text-align: right;
}

.separator {
    border-bottom: 1px solid var(--color-gray-light);
}

.otherTable {
    display: flex;
    width: 100%;
}

.otherTable::-webkit-scrollbar {
    background-color: transparent;
    width: 5px;
    height: 4px;
    border: 1px solid transparent;
}

.otherTable::-webkit-scrollbar-thumb {
    background-color: var(--color-gray-light);
    border-radius: 8px;
}

.column > *:not(.separator) {
    padding: 0 8px;
}

.title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
}

.hoverElem {
    background-color: var(--color-gray-2);
}

.number, .percent {
    min-height: 20px;
    white-space: nowrap;
}

.transposedTable {
    display: flex;
    flex-direction: column;
}

.transposedRow {
    display: flex;
}

.percent, .number {
    /* appropriate styling for your cells */
}


.trcolumn {
    margin-left: 15px;
    display: flex;
    text-align: right;
}


.trseparator {
    display: flex;
    border-bottom: 1px solid var(--color-gray-light);
    margin: 2px 0;
}


.trseparator {
    border-bottom: 1px solid var(--color-gray-light);
}

.buttonmore {
    max-width: 150px;
    width: 100%;
    background-color: var(--color-gray-2);
    color: var(--color-gray-9);
    padding: 6px 6px;
    border-radius: 5px;
    border: solid 1px transparent;
}

.buttonmore:hover {
    border: solid 1px var(--color-gray-9);
}


.tableWrapper {
    display: flex;
    flex-direction: column;
}

.headersRow, .percentRow {
    display: flex;
    align-items: center;
}

.columnHeader, .percentColumn {
    flex: 1;
    display: flex;
    justify-content: flex-start;
}

.dataCell {
    flex: 1;
    display: flex;
    justify-content: center;
}

.showMoreButton {
    text-align: center;
    margin-top: 10px;
}


.dataRow {
    display: flex;
    flex-direction: column;
}

.cl.trtitle {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 50px; /* Можно настроить под ваш дизайн */
}


.trmainColumn {
    min-width: 250px;
}

.trmainColumn, .trcolumn {
    display: flex;
}

.trmainColumn > span:first-child {
    font-weight: 600;
}

.trmainColumn > span {
    width: 100%;
}

.trpaddingLeft {
    padding-left: 16px;
}

.hoverElem {
    background-color: var(--color-highlight); /* Установите нужный цвет подсветки */
}

.cl.dataRow {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
}

/* Стиль для подсветки всей строки */
/* .hoverElem {
  background-color: var(--color-gray-2);
} */
.hoverElem {
    background-color: var(--color-gray-2); /* Цвет подсветки */
}

/* Дополнительные стили для контейнера строк */
.cl.row {
    display: flex;
    /* Другие необходимые стили */
}

/* Стили для ячеек данных */
.cl.number {
    /* Стили для текста в ячейках */
    padding: 10px; /* Пример отступа */
}

.cl.otherTable {
    display: flex;
    flex-direction: column;
}

.cl.row {
    display: flex;
    justify-content: space-between;
}


.ellipsis {
    white-space: nowrap;
    overflow: hidden; /* Hide any content that overflows the box */
    /* text-overflow: ellipsis; Show ellipsis (...) when text overflows */
    font-weight: 500;
}


.trwrapper {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
}


.trmainColumn {
    min-width: 40px;
    padding-bottom: 15px;
}

.trmainColumn {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.trmainColumn > span:first-child {
    font-weight: 600;
}

.trflexRow {
    display: flex;
    align-items: center;
}


.specialtrcolumn {
    display: flex;
    gap: 10px;
}


.columnTitle {
    width: 205px;
    text-align: left;
    white-space: normal; /* если хотите, чтобы текст переносился */
}

.hoverElem {
    background-color: #e6f7ff; /* or whatever color you want for the hover effect */
}

/* Add additional styles as needed */


.trcolumNumber {
    display: flex;
}


.trnumber {
    min-height: 20px;
    white-space: nowrap;
}

.trnumberumberColumn {
    margin-left: 30px;
    align-items: right;
}


.columnNumberspace {
    width: 110px;
}

.trheaderRow {
    display: flex;
    align-items: center;
}

.rightPosition {
    text-align: right;
}

.pracentTitles {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
}

.pracentWidth {
    flex: 1;
    text-align: right;
    white-space: nowrap; /* Prevent line break */
    overflow: hidden; /* Ensure ellipsis if overflow */
    text-overflow: ellipsis; /* Show ellipsis */
    min-width: 0; /* Allows the element to shrink */
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
    .pracentWidth {
        flex: none;
        width: auto;
        margin-left: 5px;
    }

    .pracentTitles {
        justify-content: space-between;
    }
}

.rowContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.tableContainer {
  display: flex;
  flex-direction: column;
}

.tableHeaderRow {
  display: flex;
  width: 100%;
}

.headerCell {
  flex: 1;
  text-align: center;
  /* При необходимости задайте фиксированную или минимальную ширину */
}





